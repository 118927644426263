import React from 'react';
import { CollectionPage } from './Collection/CollectionPage';
import {
  IToast,
  useToast,
} from '../../../common/context/ToastContext/ToastContext';
import { classes } from './Collection.st.css';
import { Text } from 'wix-ui-tpa';
import { IUser, useEnvironment } from '@wix/yoshi-flow-editor';
import { Status } from '../../../consts/collectionsConsts';
import { Actions } from '../types';
import { useModal } from '../../../common/context/ModalsContext/ModalsContext';
import { useCustomCallback } from '../../../common/hooks/useCustomCallback';

export type CollectionWidgetProps = {
  mediaCollections: MediaCollections;
  instance: string;
  instanceId: string;
  appParams: object;
  host: any;
  albumData: AlbumData;
  collectionsWixCodeApi: CollectionWixCodeApiFuncs;
  navigator: INavigator;
  collection: MediaCollection;
  galleryActions: GalleryActions;
  user: IUser;
  membersStatus: MemberStatus[];
  navigateToMember: (memberId: string, memberSlug: string) => void;
  comments?: MultiCommentsData;
  actions: Actions;
  refetchMembers: () => void;
  userStatus: Status;
  asyncFunc: () => Promise<void>;
  controllerToast: IToast;
  actionCallbacks: Callbacks;
  promiseResult: string;
  queryParams?: { [key: string]: string };
  onRefuseJoinRequest?: () => void;
  onRefuseLoginRequest?: () => void;
};

export const CollectionWidget = (props: CollectionWidgetProps) => {
  const {
    albumData,
    collectionsWixCodeApi,
    navigator,
    collection,
    galleryActions,
    membersStatus,
    navigateToMember,
    comments,
    actions,
    userStatus,
    queryParams,
  } = props;

  const { showModal, closeModal } = useModal();
  const { triggerToast } = useToast();
  const onNavigateToGallery = () => {
    albumData.sets.gallery.length >= 2
      ? navigator.sets()
      : navigator.set(albumData.sets.gallery[0].id);
  };

  useCustomCallback({
    actionCallbacks: props.actionCallbacks,
    functions: {
      triggerToast,
      navigateToLists: navigator.collectionsList,
      closeModal,
      showGallery: galleryActions.showGallery,
      openWindow: (url: string) => window.open(url, '_blank'),
      showModal,
    },
  });

  return (
    <div dir="ltr">
      <CollectionPage
        collection={collection}
        navigator={navigator}
        collectionsWixCodeApi={collectionsWixCodeApi}
        albumData={albumData}
        galleryActions={galleryActions}
        userStatus={userStatus}
        membersStatus={membersStatus}
        navigateToMember={navigateToMember}
        comments={comments}
        actions={actions}
        queryParams={queryParams}
        onNavigateToGallery={onNavigateToGallery}
      />
    </div>
  );
};
