import React from 'react';
import { classes } from '../CollectionPage.st.css';
import { Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  translateWithParams,
  months,
  getVisitorStatus,
} from '@wix/albums-uou-common';

interface CollectionDetailsProps {
  collection: MediaCollection;
  userStatus: Status;
}

export const CollectionDetails = ({
  collection,
  userStatus,
}: CollectionDetailsProps) => {
  const { t } = useTranslation();
  const date = new Date(collection.createdDate);
  return (
    <div className={classes.collectionDetails}>
      <Text className={classes.collectionName}>{collection?.name}</Text>
      <Text className={classes.collectionDescription}>
        {translateWithParams(t, 'COLLECTION_LIST_PAGE_SUBTITLE', {
          nickname: collection.creator.nickname,
          month: months[date.getMonth()],
          status: getVisitorStatus(t, userStatus),
          day: date.getDate(),
          year: date.getFullYear(),
          membersCount: collection.membersCount,
        })}
      </Text>
    </div>
  );
};
