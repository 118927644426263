import React, { useCallback } from 'react';
import { Divider, Text, Badge, BadgePriority, Avatar } from 'wix-ui-tpa';

import { st, classes } from './Member.st.css';

import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  getStatusTitleKey,
  ModalType,
  Status,
} from '../../../../../../consts/collectionsConsts';
import {
  ThreeDotsMenu,
  MenuItem,
} from '../../../../../../common/components/ThreeDotsMenu/ThreeDotsMenu';
import { useModal } from '../../../../../../common/context/ModalsContext/ModalsContext';
import { useCollectionsBi } from '../../../../../../common/context/BiContext/BiContext';

type MemberProps = {
  member: Member;
  status: Status;
  navigateToMember: (memberId: string, memberSlug: string) => void;
  isMobile: boolean;
  isAdmin: boolean;
};

export const Member = React.memo(
  ({ member, navigateToMember, status, isMobile, isAdmin }: MemberProps) => {
    const { t } = useTranslation();
    const { showModal } = useModal();
    const statusText = t(getStatusTitleKey(status));
    const { biLogger } = useCollectionsBi();

    const openMemberProfile = useCallback(() => {
      navigateToMember(member.memberId, member.slug);
    }, [member]);

    const currentMemberIsAdmin = status === Status.ADMIN;

    const menuItems: MenuItem[] = [
      {
        contentKey: 'COLLECTION_MEMBERS_MEMBER_VIEW',
        onClick: () => navigateToMember(member.memberId, member.slug),
      },
      {
        contentKey: 'COLLECTION_MEMBERS_MEMBER_REMOVE',
        onClick: () =>
          showModal({
            type: ModalType.REMOVE_MEMBER,
            actionArgs: { memberId: member.memberId },
            additionalData: {
              memberName: member.nickname,
            },
          }),
        hidden: !isAdmin || currentMemberIsAdmin,
      },
    ];

    return (
      <div className={st(classes.root, { mobile: isMobile })}>
        <div className={classes.content}>
          <div
            className={st(classes.details, { mobile: isMobile })}
            onClick={openMemberProfile}
          >
            <Avatar
              className={st(classes.photo, { mobile: isMobile })}
              // name={member.nickname}
              src={member.photoUrl}
            />
            <Text className={st(classes.name, { mobile: isMobile })}>
              {member.nickname}
            </Text>
            <Badge priority={BadgePriority.light} className={classes.status}>
              {statusText}
            </Badge>
          </div>
          <ThreeDotsMenu menuItems={menuItems} />
        </div>
        {isMobile && <Divider className={classes.divider} />}
      </div>
    );
  },
);
