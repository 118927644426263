import React from 'react';
import { MembersList } from '../common/MembersList';
import { Status } from '../../../../../../consts/collectionsConsts';

type MembersTabProps = {
  membersStatus: MemberStatus[];
  navigateToMember: (memberId: string, memberSlug: string) => void;
  userStatus: Status;
};

export const MembersTab = React.memo(
  ({ membersStatus, navigateToMember, userStatus }: MembersTabProps) => {
    const isAdmin = userStatus === Status.ADMIN;
    return (
      <MembersList
        membersStatus={membersStatus}
        navigateToMember={navigateToMember}
        isAdmin={isAdmin}
      />
    );
  },
);
