import React, { useEffect, useState, useRef } from 'react';
import { st, classes } from './GalleryTab.st.css';
import { getLayoutData } from './galleryTabConsts';
import { GalleryLayout } from '../../../../../../consts/galleryConsts';
import { GalleryTabEmptyState } from './GalleryTabEmptyState';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { Actions } from '../../../../types';
import {
  CollectionItemsFilter,
  getFilteringOptions,
  optionsIDs,
} from './CollectionItemsFilter';
import { Status } from '../../../../../../consts/collectionsConsts';

export type GalleryTabProps = {
  collection: MediaCollection;
  collectionsWixCodeApi: CollectionWixCodeApiFuncs;
  galleryActions: GalleryActions;
  comments?: MultiCommentsData;
  onNavigateToGallery: () => void;
};

// type ArrayType = T[] extends (infer T)[] ? T : never;

const getEmptyState = ({
  currentOption,
  onNavigateToGallery,
}: {
  currentOption: ReturnType<typeof getFilteringOptions>[0];
  onNavigateToGallery: () => void;
}) => {
  return (
    <GalleryTabEmptyState
      currentOption={currentOption}
      onNavigateToGallery={onNavigateToGallery}
    />
  );
};

export const GalleryTab = ({
  collection,
  collectionsWixCodeApi,
  galleryActions,
  comments,
  onNavigateToGallery,
}: GalleryTabProps) => {
  const { t } = useTranslation();
  const filterOptions = getFilteringOptions({ comments, collection, t });
  const [currentOption, setCurrentOption] = useState(filterOptions[0]);
  const { isMobile } = useEnvironment();

  useEffect(() => {
    if (
      currentOption.id === optionsIDs.ALL &&
      collection.items.length !== currentOption.items.length
    ) {
      setCurrentOption({ ...currentOption, items: collection.items });
    }
  }, [collection]);

  useEffect(() => {
    if (currentOption.items?.length === 0) {
      galleryActions.hideGallery();
    } else {
      galleryActions.showGallery();
    }
  }, [currentOption]);

  useEffect(() => {
    return () => collectionsWixCodeApi.setItems(collection.items);
  }, []);

  useEffect(() => {
    const layout = getLayoutData(GalleryLayout.MASONRY);

    collectionsWixCodeApi.setStyleParams(layout.styleParams);
  }, []);

  if (
    currentOption.id === optionsIDs.ALL &&
    currentOption.items?.length === 0
  ) {
    getEmptyState({ currentOption, onNavigateToGallery });
  }

  return (
    <>
      <div className={st(classes.root, { mobile: isMobile })}>
        <CollectionItemsFilter
          isMobile={isMobile}
          collection={collection}
          collectionsWixCodeApi={collectionsWixCodeApi}
          currentOption={currentOption}
          setCurrentOption={setCurrentOption}
          options={filterOptions}
        />
      </div>
      {currentOption.items?.length === 0 &&
        getEmptyState({ currentOption, onNavigateToGallery })}
    </>
  );
};
