import React, { Suspense } from 'react';
import { st, classes } from './DiscussionTab.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { CollectionDetails } from '../common/CollectionDetails';
import { CollectionNameAndDescription } from '../common/CollectionNameAndDescription';
import { MembersList } from '../common/MembersList';
import { Status } from '../../../../../../consts/collectionsConsts';
import { Text } from 'wix-ui-tpa';

const CollectionsWixComments = React.lazy(
  () =>
    import(
      /* webpackChunkName: "collections-comments" */ '../../../../../../common/components/CommentsWrapper/CommentsWrapper'
    ),
);

type DiscussionTabProps = {
  collection: MediaCollection;
  membersStatus: MemberStatus[];
  navigateToMember: (memberId: string, memberSlug: string) => void;
  userStatus: Status;
};

export const DiscussionTab = ({
  collection,
  membersStatus,
  navigateToMember,
  userStatus,
}: DiscussionTabProps) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const isAdmin = userStatus === Status.ADMIN;
  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <div className={st(classes.commentsWrapper, { mobile: isMobile })}>
        <Suspense fallback={<div></div>}>
          <CollectionsWixComments
            collectionId={collection.id}
            resourceId={collection.id}
            hideEmptyState={false}
            isItem={false}
          />
        </Suspense>
      </div>
      {!isMobile && (
        <div className={classes.additionalInfo}>
          <div className={classes.about}>
            <Text className={classes.boxTitle}>
              {t('COLLECTION_TABS_DISCUSSION_ABOUT')}
            </Text>
            <Text className={classes.collectionDesc}>
              {collection.description}
            </Text>
            <CollectionDetails collection={collection} />
          </div>
          <div className={classes.members}>
            <Text className={classes.boxTitle}>
              {t('COLLECTION_TABS_DISCUSSION_MEMBERS')}
            </Text>
            <MembersList
              membersStatus={membersStatus}
              navigateToMember={navigateToMember}
              isAdmin={isAdmin}
            />
          </div>
        </div>
      )}
    </div>
  );
};
