import { GalleryTab } from './Gallery/GalleryTab';
import { DiscussionTab } from './Discussion/DiscussionTab';
import { MembersTab } from './Members/MembersTab';
import { AboutTab } from './About/AboutTab';
import _ from 'lodash';

export enum TABS_IDS {
  ABOUT,
  GALLERY,
  DISCUSSION,
  MEMBERS,
}

export const COLLECTION_PAGE_TABS = {
  GALLERY: {
    name: 'gallery',
    tabId: TABS_IDS.GALLERY,
    title: 'COLLECTION_TABS_SELECTED_MEDIA',
    component: GalleryTab,
  },
  DISCUSSION: {
    name: 'discussion',
    tabId: TABS_IDS.DISCUSSION,
    title: 'COLLECTION_TABS_DISCUSSION',
    component: DiscussionTab,
  },
  MEMBERS: {
    name: 'members',
    tabId: TABS_IDS.MEMBERS,
    title: 'COLLECTION_TABS_MEMBERS',
    component: MembersTab,
  },
  ABOUT: {
    name: 'about',
    tabId: TABS_IDS.ABOUT,
    title: 'COLLECTION_TABS_ABOUT',
    component: AboutTab,
  },
};

const MOBILE_COLLECTION_TABS = Object.values(COLLECTION_PAGE_TABS);

const DESKTOP_COLLECTION_TABS = [
  COLLECTION_PAGE_TABS.GALLERY,
  COLLECTION_PAGE_TABS.DISCUSSION,
];

export const getTranslatedTabsArray = (
  t: (key: string) => string,
  isMobile: boolean,
) => {
  const tabs = isMobile ? MOBILE_COLLECTION_TABS : DESKTOP_COLLECTION_TABS;
  const tabsArray = tabs.map((tab) => {
    return { ...tab, title: t(tab.title) };
  });
  return tabsArray;
};
