import { Status } from '../../../consts/collectionsConsts';
import {
  OwnerLogger,
  VisitorLogger,
} from '@wix/yoshi-flow-editor/external-types/bi';
import _ from 'lodash';

type Logger = OwnerLogger | VisitorLogger;
// type FunctionPropertyNames<T> = {
//   [K in keyof T]: T[K] extends Function ? K : never;
// }[keyof T];
// type EventType = FunctionPropertyNames<Logger>;
// type Log = <K extends EventType>(
//   biFunc: Function,
//   ...args: Parameters<Logger[K]>
// ) => void;

type Log = (biFunc: Function, ...args: any) => void;

type BiLoggerProps = {
  albumId: string;
  instance_id: string;
  visitorId: string;
  role: Role;
  collectionId?: string;
  bi: Logger;
};

export const createBiLogger = ({
  albumId,
  instance_id,
  visitorId,
  role,
  collectionId,
  bi,
}: BiLoggerProps) => {
  const defaultParams = {
    albumID: albumId,
    instance_id,
    visitorId,
    role,
    collection_id: collectionId,
  };

  const log: Log = (biFunc: Function, additionalProps: any) => {
    bi && bi.report(biFunc({ ...defaultParams, ...additionalProps }));
  };

  return { log };
};

export type BiLogger = ReturnType<typeof createBiLogger>;
