import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { ModalType } from '../../../consts/collectionsConsts';

const ModalsContext = React.createContext({} as ModalsContextValue);

type ModalData = {
  type?: ModalType;
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  additionalData?: any;
  actionArgs?: any;
};

type ModalsContextValue = {
  showModal: (modalData: ModalData) => void;
  closeModal: () => void;
};

export const ModalsProvider = ({
  children,
  modalGenerator,
}: {
  children: any;
  modalGenerator: (data: ModalData) => JSX.Element;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const showModal = (data: ModalData) => {
    if (!isOpen) {
      setIsOpen(true);
    }
    setModalData({
      ...data,
      onClose: () => {
        data.onClose && data.onClose();
        closeModal();
      },
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const modal = modalGenerator(modalData);

  return (
    <ModalsContext.Provider
      value={{
        showModal,
        closeModal,
      }}
    >
      {isOpen && ReactDOM.createPortal(modal, document.body)}
      {children}
    </ModalsContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalsContext);
};
