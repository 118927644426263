import React from 'react';
import { BasicModal, IBasicModal } from '../BasicModal/BasicModal';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { translateWithParams } from '../../../utils';

type RemoveMemberModalProps = {
  onSave?: (res?: any) => void;
  onCancel?: () => void;
  isOpen?: boolean;
  memberName: string;
};

export const RemoveMemberModal = ({
  isOpen,
  onSave,
  onCancel = () => {},
  memberName,
}: RemoveMemberModalProps) => {
  const { t } = useTranslation();

  const modalProps = {
    primaryButtonText: t(`REMOVE_MEMBER_MODAL_CONFIRM_CTA`),
    onPrimaryButtonClick: () => {
      onSave && onSave();
    },
    secondaryButtonText: t(`REMOVE_MEMBER_MODAL_CANCEL_CTA`),
    onSecondaryButtonClick: onCancel,
    onClose: onCancel,
    title: t(`REMOVE_MEMBER_MODAL_TITLE`),
    content: translateWithParams(t, `REMOVE_MEMBER_MODAL_CONTENT`, {
      memberName,
    }),
    isOpen,
  } as IBasicModal;

  return <BasicModal {...modalProps} />;
};
