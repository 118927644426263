import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { st, classes } from './CollectionHeaderMenu.st.css';

import Delete from 'wix-ui-icons-common/Delete';

import {
  Status,
  ActionType,
  ModalType,
} from '../../../../../consts/collectionsConsts';
import { useModal } from '../../../../../common/context/ModalsContext/ModalsContext';
import _ from 'lodash';
import {
  ThreeDotsMenu,
  MenuItemType,
  MenuItem,
} from '../../../../../common/components/ThreeDotsMenu/ThreeDotsMenu';
import { useToast } from '../../../../../common/context/ToastContext/ToastContext';
import {
  isListMember,
  isListAdmin,
  isNotLoggedIn,
} from '../../../../../common/helpers/permissions';
import { Actions } from '../../../types';

type CollectionHeaderMenuProps = {
  collection: MediaCollection;
  navigator: INavigator;
  memberStatus: Status;
  actions: Actions;
  onInviteClick?: () => void;
};

export const CollectionHeaderMenu = ({
  collection,
  navigator,
  memberStatus,
  onInviteClick,
  actions,
}: CollectionHeaderMenuProps) => {
  const isAdmin = isListAdmin(memberStatus);
  const isMember = isListMember(memberStatus);
  const isLoggedIn = !isNotLoggedIn(memberStatus);

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const { showModal } = useModal();
  const { triggerToast } = useToast();

  const canEditCollectionInfo = isAdmin;
  const inviteIsInsideMenu = isMobile && isMember;

  const dynamicAction = isAdmin ? ActionType.DELETE : ActionType.LEAVE;

  const menuItems: MenuItem[] = [
    {
      contentKey: 'COLLECTIONS_COLLECTION_PAGE_JOIN_CTA',
      onClick: () => {
        actions.JOIN(JSON.stringify({ origin: 'join from list page' }));
      },
      hidden: isAdmin || isMember,
    },
    {
      contentKey: 'COLLECTIONS_COLLECTION_ACTIONS_MENU_EDIT',
      onClick: () =>
        showModal({
          type: ModalType.EDIT_INFO,
          onSuccess: () =>
            triggerToast({ content: t('COLLECTION_EDIT_INFO_TOAST_SUCCESS') }),
          onError: () =>
            triggerToast({ content: t('COLLECTION_EDIT_INFO_TOAST_FAILURE') }),
        }),
      hidden: !canEditCollectionInfo,
    },
    {
      contentKey: 'COLLECTIONS_COLLECTION_PAGE_INVITE_CTA',
      onClick: onInviteClick ? onInviteClick : () => {},
      hidden: !inviteIsInsideMenu,
    },
    {
      type: MenuItemType.DIVIDER,
      hidden: !canEditCollectionInfo && !inviteIsInsideMenu,
    },
    {
      contentKey: `COLLECTIONS_COLLECTION_ACTIONS_MENU_${dynamicAction}`,
      hidden: !isLoggedIn,
      onClick: () =>
        showModal({
          type: isAdmin ? ModalType.DELETE : ModalType.LEAVE,
          onSuccess: () => {
            if (isAdmin) {
              navigator.collectionsList();
            }
          },
        }),
      prefixIcon: isAdmin ? <Delete className={classes.icon} /> : undefined,
    },
  ];

  return <ThreeDotsMenu menuItems={menuItems} placement="left-start" />;
};
