import React from 'react';
import { Button, Text } from 'wix-ui-tpa';
import {
  useEnvironment,
  useTranslation,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { st, classes } from './GalleryTabEmptyState.st.css';
import { Actions } from '../../../../types';
import { Status } from '../../../../../../consts/collectionsConsts';
import { Option, optionsIDs } from './CollectionItemsFilter';

type GalleryTabEmptyStateProps = {
  currentOption: Option;
  onNavigateToGallery: () => void;
};

export const GalleryTabEmptyState = ({
  currentOption,
  onNavigateToGallery,
}: GalleryTabEmptyStateProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const onClick = () => {
    onNavigateToGallery();
  };

  const ctaKey = 'COLLECTION_GALLERY_EMPTY_STATE_CTA_V2';
  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        onlyTitle: currentOption.id !== optionsIDs.ALL,
      })}
    >
      {currentOption.id === optionsIDs.ALL && (
        <>
          <Text className={st(classes.subtitle, { mobile: isMobile })}>
            {t('COLLECTION_GALLERY_EMPTY_STATE_SUBTITLE_V2')}
          </Text>
          <Button
            data-hook="gallery-empty-state-select-btn"
            onClick={onClick}
            className={classes.action}
          >
            {t(ctaKey)}
          </Button>
        </>
      )}
    </div>
  );
};
