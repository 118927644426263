import React from 'react';
import { BasicModal, IBasicModal } from '../BasicModal/BasicModal';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { translateWithParams } from '../../../utils';

type DeleteCollectionModalProps = {
  onSave?: (res?: any) => void;
  onCancel?: () => void;
  isOpen?: boolean;
  collectionName: string;
};

export const DeleteCollectionModal = ({
  isOpen,
  onSave,
  collectionName,
  onCancel = () => {},
}: DeleteCollectionModalProps) => {
  const { t } = useTranslation();

  const modalProps = {
    primaryButtonText: t(`DELETE_COLLECTION_MODAL_CONFIRM_CTA`),
    onPrimaryButtonClick: () => {
      onSave && onSave();
    },
    secondaryButtonText: t(`DELETE_COLLECTION_MODAL_CANCEL_CTA`),
    onSecondaryButtonClick: onCancel,
    onClose: onCancel,
    title: t(`DELETE_COLLECTION_MODAL_TITLE`),
    content: translateWithParams(t, `DELETE_COLLECTION_MODAL_CONTENT`, {
      listName: collectionName,
    }),
    isOpen,
  } as IBasicModal;

  return <BasicModal {...modalProps} />;
};
