import React, { useContext } from 'react';
import { useBi } from '@wix/yoshi-flow-editor';
import { createBiLogger } from './biLogger';

type BiContextProps = {
  albumId: string;
  instance_id: string;
  visitorId: string;
  role: Role;
  collectionId?: string;
};

export const BiContext = React.createContext(
  {} as { biLogger: TBiContextValue },
);

type TBiContextValue = ReturnType<typeof createBiLogger>;

export const BiProvider = ({
  albumId,
  instance_id,
  visitorId,
  role,
  collectionId,
  children,
}: BiContextProps & { children: any }) => {
  const bi = useBi();
  const biLogger = createBiLogger({
    albumId,
    instance_id,
    visitorId,
    role,
    collectionId,
    bi,
  });

  return (
    <BiContext.Provider
      value={{
        biLogger,
      }}
    >
      {children}
    </BiContext.Provider>
  );
};
export const useCollectionsBi = () => {
  return useContext(BiContext);
};
