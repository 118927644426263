import React from 'react';
import { st, classes } from './CollectionNameAndDescription.st.css';
import { Text } from 'wix-ui-tpa';

import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

export const CollectionNameAndDescription = ({
  collection,
  additionalButton,
}: {
  collection: MediaCollection;
  additionalButton?: JSX.Element;
}) => {
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.texts, { mobile: isMobile })}>
      <div className={classes.titleContainerMobile}>
        <Text className={st(classes.collectionName, { mobile: isMobile })}>
          {collection.name}
        </Text>
        {additionalButton}
      </div>

      <Text className={st(classes.collectionDesc, { mobile: isMobile })}>
        {collection.description}
      </Text>
    </div>
  );
};
