import GridIcon from '../../../../../../assets/icons/layouts/grid.svg';
import MasonryIcon from '../../../../../../assets/icons/layouts/masonry.svg';
import { getDefaultStyleParamsByLayout } from '../../../../../../consts/defautStyleParams';
import { GalleryLayout } from '../../../../../../consts/galleryConsts';

export const getLayoutData = (layout: GalleryLayout, isMobile?: boolean) => {
  switch (layout) {
    case GalleryLayout.MASONRY:
      return {
        type: GalleryLayout.MASONRY,
        Icon: MasonryIcon,
        NextIcon: GridIcon,
        styleParams: getDefaultStyleParamsByLayout({
          layout: GalleryLayout.MASONRY,
          isMobile,
        }),
      };
    case GalleryLayout.GRID:
    default:
      return {
        type: GalleryLayout.GRID,
        Icon: GridIcon,
        NextIcon: MasonryIcon,
        styleParams: getDefaultStyleParamsByLayout({
          layout: GalleryLayout.GRID,
          isMobile,
        }),
      };
  }
};
