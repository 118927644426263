import React, { useEffect, useState } from 'react';
import { st, classes } from './CollectionPage.st.css';
import 'pro-gallery/dist/statics/main.css';
import {
  getTranslatedTabsArray,
  TABS_IDS,
  COLLECTION_PAGE_TABS,
} from './Tabs/tabs';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import {
  Tabs,
  TabsAlignment,
  TabsSkin,
  TabsVariant,
  Divider,
  Text,
} from 'wix-ui-tpa';
import { useScrollPosition } from '../../../../common/hooks/useScrollPostion';
import cx from 'classnames';
import { handleMobileScroll } from './collectionMobileScrollHelper';
import { Status } from '../../../../consts/collectionsConsts';
import { Actions } from '../../types';
import _ from 'lodash';
import { isListMember } from '../../../../common/helpers/permissions';
import { useCollectionsBi } from '../../../../common/context/BiContext/BiContext';
import { navigationInCollectionMemberArea } from '@wix/bi-logger-photo-ugc/v2';
import { CollectionHeaderMenu } from './Header/CollectionHeaderMenu';
import { CollectionDetails } from './Header/CollectionDetailsHeader';
import { isAlbumTemplateV8 } from '../../../../common/utils';

const ScrollDirection = {
  up: 'up',
  down: 'down',
} as const;

type CollectionPageProps = {
  collection: MediaCollection;
  navigator: INavigator;
  collectionsWixCodeApi: CollectionWixCodeApiFuncs;
  albumData: AlbumData;
  galleryActions: GalleryActions;
  userStatus: Status;
  membersStatus: MemberStatus[];
  navigateToMember: (memberId: string, memberSlug: string) => void;
  comments?: MultiCommentsData;
  actions: Actions;
  queryParams?: { [key: string]: string };
  onNavigateToGallery: () => void;
};

export const CollectionPage = ({
  collection,
  navigator,
  collectionsWixCodeApi,
  albumData,
  galleryActions,
  userStatus,
  membersStatus,
  navigateToMember,
  actions,
  comments,
  queryParams,
  onNavigateToGallery,
}: CollectionPageProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { biLogger } = useCollectionsBi();

  const collectionTabs = getTranslatedTabsArray(t, isMobile);

  const initialTabId =
    queryParams?.tab?.toLocaleLowerCase() ===
    COLLECTION_PAGE_TABS.DISCUSSION.name
      ? TABS_IDS.DISCUSSION
      : TABS_IDS.GALLERY;

  const [currentTabIdx, setCurrentTabIdx] = useState(
    _.findIndex(collectionTabs, (tab) => tab.tabId === initialTabId),
  );

  const [animationClass, setAnimationClass] = useState('');
  const [fixTabs, setFixTabs] = useState(false);

  const currentTab = collectionTabs[currentTabIdx];
  const TabComponent = currentTab.component;
  const shouldFixOnlyTabs =
    collectionTabs[currentTabIdx].tabId !== TABS_IDS.GALLERY;

  useEffect(() => {
    if (collectionTabs[currentTabIdx].tabId !== TABS_IDS.GALLERY) {
      galleryActions.hideGallery();
    }
    // GalleryTab is responsible for showing the gallery if needed
  }, [currentTabIdx, collection]);

  // taken from editor elements HeaderContainer
  let direction: (typeof ScrollDirection)[keyof typeof ScrollDirection] =
    ScrollDirection.down;
  let directionFlipPosition = 0;

  useScrollPosition(
    ({ currPos, prevPos }) => {
      const result = handleMobileScroll({
        direction,
        directionFlipPosition,
        currPos,
        prevPos,
        isMember: isListMember(userStatus),
      });
      direction = result.direction;
      directionFlipPosition = result.directionFlipPosition;
      if (result.animationClass) {
        setAnimationClass(result.animationClass);
      }
      setFixTabs(result.fixTabs);
    },
    [animationClass, currentTabIdx, collection.items],
    {
      disabled: !isMobile || collection.items.length < 20,
      // TODO: find better way to know when to disable
    },
  );

  const isAlbumV8 = isAlbumTemplateV8(albumData.settings);

  const tabsProps = {
    collection,
    userStatus,
    membersStatus,
    navigateToMember,
    collectionsWixCodeApi,
    galleryActions,
    onEditInfo: () => {},
    comments,
    actions,
    onNavigateToGallery,
    isAlbumV8,
  };

  return (
    <div id="collection-page" className={classes.container}>
      <div
        className={cx(
          st(classes.root, {
            mobile: isMobile,
            fixed: fixTabs && !shouldFixOnlyTabs,
            [animationClass]: !shouldFixOnlyTabs,
          }),
        )}
      >
        {!isAlbumV8 && !isMobile && (
          <CollectionDetails collection={collection} userStatus={userStatus} />
        )}
        <div className={classes.headerContainer}>
          <div className={classes.tabsContainer}>
            <Tabs
              className={cx(
                st(classes.tabs, {
                  mobile: isMobile,
                  fixed: fixTabs && shouldFixOnlyTabs,
                  [animationClass]: shouldFixOnlyTabs,
                }),
              )}
              activeTabIndex={currentTabIdx}
              alignment={TabsAlignment.left}
              items={collectionTabs}
              onTabClick={(selectedTabIndex: number) => {
                setCurrentTabIdx(selectedTabIndex);
                biLogger.log(navigationInCollectionMemberArea, {
                  category: collectionTabs[selectedTabIndex].title,
                });
              }}
              skin={TabsSkin.clear}
              variant={TabsVariant.fit}
            ></Tabs>
          </div>
          <div className={classes.headerMenu}>
            <CollectionHeaderMenu
              actions={actions}
              navigator={navigator}
              collection={collection}
              memberStatus={userStatus}
            />
          </div>
        </div>
        <Divider />
        <TabComponent {...tabsProps} />
      </div>
    </div>
  );
};
