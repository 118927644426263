import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Text,
  TextTypography,
  IconButton,
  IconButtonSkins,
} from 'wix-ui-tpa';
import { st, classes } from './ShareModal.st.css';
import Facebook from 'wix-ui-icons-common/Facebook';
import Whatsapp from 'wix-ui-icons-common/Whatsapp';
import EmailFilled from 'wix-ui-icons-common/EmailFilled';
import Link from 'wix-ui-icons-common/Link';
import { escapeSpecialChars } from '../../../utils';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useToast } from '../../../context/ToastContext/ToastContext';

export interface IShaerModal {
  isOpen: boolean;
  onClose?: () => void;
  collectionUrl: string;
  albumName: string;
  collectionName: string;
}

export const ShareModal = ({
  isOpen,
  onClose,
  collectionUrl,
  albumName,
  collectionName,
}: // isMobile = false,
IShaerModal) => {
  const [animateShow, setAnimateShow] = useState(false);

  useEffect(() => {
    setAnimateShow(true);
  }, []);

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { triggerToast } = useToast();

  const encodedCollectionName = escapeSpecialChars(collectionName);
  const encodedAlbumName = escapeSpecialChars(albumName);

  const inviteOptions = [
    {
      icon: <Whatsapp className={classes.icon} />,
      onClick: () =>
        window.open(
          `https://wa.me/send?text=${t('COLLECTION_INVITE_WHATSAPP_MESSAGE', {
            listName: encodedCollectionName,
            albumName: encodedAlbumName,
          })} ${collectionUrl}`,
        ),
    },
    {
      icon: <EmailFilled className={classes.icon} size="28px" />,
      onClick: () =>
        window.open(
          `mailto:?subject=${t('Proofing_Email_Photographer_Title', {
            listname: encodedCollectionName,
          })}&body=${t('Proofing_Email_Photographer_Body', {
            listName: encodedCollectionName,
            albumName: encodedAlbumName,
          })} ${t('Proofing_Email_Photographer_CTA')} ${collectionUrl}`,
        ),
    },
    {
      icon: <Link className={classes.icon} />,
      onClick: async () => {
        await navigator.clipboard.writeText(collectionUrl);
        triggerToast({ content: t('COLLECTION_INVITE_MODAL_COPY_TOAST') });
      },
    },
  ];

  return (
    <Dialog
      className={classes.root}
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={st(classes.modalWrapper, {
        animateShow,
      })}
    >
      <div className={st(classes.container, { mobile: isMobile })}>
        <Text
          className={st(classes.title, { mobile: isMobile })}
          typography={TextTypography.largeTitle}
        >
          {t('COLLECTION_INVITE_MODAL_TITLE')}
        </Text>
        <div className={classes.inviteOptions}>
          {inviteOptions.map((option) => (
            <IconButton
              className={classes.iconButton}
              skin={IconButtonSkins.Full}
              icon={option.icon}
              onClick={option.onClick}
            />
          ))}
        </div>
      </div>
    </Dialog>
  );
};
