import React, { useMemo } from 'react';
import {
  ModalType,
  ActionType,
  PrivacySettings,
} from '../../../../consts/collectionsConsts';
import { LeaveCollectionModal } from '../../../../common/components/modals/LeaveCollectionModal/LeaveCollectionModal';
import { DeleteCollectionModal } from '../../../../common/components/modals/DeleteCollectionModal/DeleteCollectionModal';
import { JoinCollectionModal } from '../../../../common/components/modals/JoinModal/JoinModal';
import { CollectionInfoModal } from '../../../../common/components/modals/CollectionInfo/CollectionInfoModal';
import { ShareModal } from '../../../../common/components/modals/ShareModal/ShareModal';
import { Actions } from '../../types';
import { ModalData } from './modalsTypes';
import { RemoveMemberModal } from '../../../../common/components/modals/RemoveMemberModal/RemoveMemberModal';
import _ from 'lodash';
import { SignUpModal } from '../../../../common/components/modals/SignUpModal/SignUpModal';

export function getModalGenerator(
  collection: MediaCollection,
  actions: Actions,
  navigator: INavigator,
  albumName: string,
  onRefuseJoinRequest?: () => void,
  onRefuseLoginRequest?: () => void,
) {
  const actionArgsToString = (params: any) => JSON.stringify(params);

  return (data: ModalData) => {
    switch (data.type) {
      case ModalType.DELETE:
        return (
          <DeleteCollectionModal
            onSave={async () => {
              data.onClose && data.onClose();
              await actions.DELETE(actionArgsToString(data.actionArgs));
              data.onSuccess && data.onSuccess();
            }}
            onCancel={data.onClose}
            isOpen={true}
            collectionName={collection.name}
          />
        );
      case ModalType.LEAVE:
        return (
          <LeaveCollectionModal
            onSave={async () => {
              data.onClose && data.onClose();
              await actions.LEAVE(actionArgsToString(data.actionArgs));
              data.onSuccess && data.onSuccess();
            }}
            onCancel={data.onClose}
            isOpen={true}
          />
        );
      case ModalType.INVITE:
        return (
          <ShareModal
            isOpen={true}
            collectionUrl={navigator.collectionPath + collection.id}
            onClose={data.onClose}
            albumName={albumName}
            collectionName={collection.name}
          />
        );
      case ModalType.JOIN:
        return (
          <JoinCollectionModal
            isOpen={true}
            onSave={async () => {
              data.onClose && data.onClose();
              await actions.JOIN(actionArgsToString(data.actionArgs));
              data.onSuccess && data.onSuccess();
            }}
            onClose={() => {
              onRefuseJoinRequest && onRefuseJoinRequest();
              data.onClose && data.onClose();
            }}
            listName={collection.name}
            title={data?.additionalData?.title}
            content={data?.additionalData?.content}
            ctaText={data?.additionalData?.ctaText}
          />
        );
      case ModalType.SIGN_UP:
        return (
          <SignUpModal
            isOpen={true}
            onSave={async () => {
              data.onClose && data.onClose();
              await actions.LOG_IN(); // prompts wix sign up/login modal
              data.onSuccess && data.onSuccess();
            }}
            onClose={() => {
              onRefuseLoginRequest && onRefuseLoginRequest();
              data.onClose && data.onClose();
            }}
            origin={data?.additionalData?.origin}
          />
        );
      case ModalType.EDIT_INFO:
        return (
          <CollectionInfoModal
            onSave={async (newData: {
              name?: string;
              privacy_settings?: PrivacySettings;
              description?: string;
            }) => {
              try {
                const fieldMask = Object.keys(newData);
                const _newData = {
                  name: newData.name,
                  description: newData.description,
                  privacySettings: newData.privacy_settings,
                }; // TOTO: ask server to accept privacySettings instead of privacy_settings
                const actionArgs = {
                  fieldMask,
                  modifiedCollection: { ...collection, ..._newData },
                };
                await actions.UPDATE(
                  actionArgsToString({
                    ...actionArgs,
                    ...data.actionArgs,
                  }),
                );
                data.onSuccess && data.onSuccess();
                data.onClose && data.onClose();
              } catch (e: any) {
                console.error(e);
                data.onError && data.onError(e);
                data.onClose && data.onClose();
              }
            }}
            onClose={data.onClose}
            {..._.pick(collection, ['name', 'privacySettings', 'description'])}
          />
        );
      case ModalType.REMOVE_MEMBER:
        return (
          <RemoveMemberModal
            isOpen={true}
            onSave={async () => {
              data.onClose && data.onClose();
              await actions.REMOVE_MEMBER(actionArgsToString(data.actionArgs));
              data.onSuccess && data.onSuccess();
            }}
            onCancel={data.onClose}
            memberName={data.additionalData?.memberName}
          />
        );

      default:
        return <div />;
    }
  };
}
