import { Pos } from '../../../../common/hooks/useScrollPostion';
import { st, classes } from './CollectionPage.st.css';

const ScrollDirection = {
  up: 'up',
  down: 'down',
} as const;

type HandeMobileScrollProps = {
  direction: typeof ScrollDirection[keyof typeof ScrollDirection];
  directionFlipPosition: number;
  currPos: Pos;
  prevPos: Pos;
  isMember?: boolean;
};

const TABS_POSITION = 56; // 147
const TABS_POSITION_WITH_CTA = 162;

export const handleMobileScroll = ({
  direction,
  directionFlipPosition,
  currPos,
  prevPos,
  isMember = true,
}: HandeMobileScrollProps) => {
  const result = {
    direction,
    directionFlipPosition,
    animationClass: '',
    fixTabs: false,
  };
  // multiply by -1 if not zero (-0 is confusing during debug)
  const currentPosition = currPos.y && currPos.y * -1;
  const prevPosition = prevPos.y && prevPos.y * -1;
  // catch scroll direction change and record scroll flip position
  // directionFlipPosition defaults to 0 (top of the page), but if we flip scroll direction,
  // this will be the new directionFlipPosition, from which we calculate when to start animation
  if (direction === ScrollDirection.down && currentPosition < prevPosition) {
    result.directionFlipPosition = prevPosition;
    result.direction = ScrollDirection.up;
  } else if (
    direction === ScrollDirection.up &&
    currentPosition > prevPosition &&
    currentPosition >= 0 &&
    prevPosition >= 0 // ignore direction flip outside scrollport, otherwise with rubber scroll on Safari header disappears at the top of the page
  ) {
    result.directionFlipPosition = prevPosition;
    result.direction = ScrollDirection.down;
  }

  if (
    // we have scrolled far enough from the position where scroll direction was flipped or we are at the top of the page
    (result.direction === ScrollDirection.up &&
      currentPosition + 1 < result.directionFlipPosition &&
      !currPos?.isAtPageBottom) ||
    currPos.y === 0 // make sure header is always visible at the top of the page. Due to rubber scroll on Safari it may disappear after overscroll
  ) {
    // show header by running amimation
    result.animationClass = 'fadeOutReverse';
  }
  if (
    result.direction === ScrollDirection.down &&
    currentPosition - result.directionFlipPosition > 0
  ) {
    // result.animationClass = classes.fadeOut;
    result.animationClass = 'noAnimation';
  }

  // Show tabs on scroll up
  const tabsPosition = isMember ? TABS_POSITION_WITH_CTA : TABS_POSITION;
  const shouldFixTabs = currentPosition > tabsPosition;
  if (shouldFixTabs) {
    result.fixTabs = true;
  }

  return result;
};
