export enum CALLBACKS {
  closeModal = 'closeModal',
  openEditMedia = 'openEditMedia',
  closeEditMedia = 'closeEditMedia',
  triggerToast = 'triggerToast',
  showModal = 'showModal',
  navigateToList = 'navigateToList',
  navigateToLists = 'navigateToLists',
  openCreateModal = 'openCreateModal',
  updateItemsState = 'updateItemsState',
  showGallery = 'showGallery',
  hideGallery = 'hideGallery',
  openWindow = 'openWindow',
}
