import React, { useEffect, useState, useRef } from 'react';

import { st, classes } from './CollectionItemsFilter.st.css';
import { FloatingDropdown } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

type Options = ReturnType<typeof getFilteringOptions>;
export type Option = Options[number];

type CollectionItemsFilterProps = {
  collection: MediaCollection;
  collectionsWixCodeApi: CollectionWixCodeApiFuncs;
  isMobile: boolean;
  currentOption: Option;
  setCurrentOption: (option: any) => void;
  options: Options;
};

export const optionsIDs = {
  ALL: '1',
  COMMENTS: '2',
  NO_COMMENTS: '3',
};

export const getFilteringOptions = ({
  comments,
  collection,
  t,
}: {
  comments?: MultiCommentsData;
  collection: MediaCollection;
  t: (key: string, data: any) => string;
}) => {
  const itemsWithComments: MediaItem[] = [];
  const itemsWithoutComments: MediaItem[] = [];

  if (comments) {
    collection.items.forEach((item: MediaItem) => {
      if (comments[item.id]?.total && comments[item.id].total > 0) {
        itemsWithComments.push(item);
      } else {
        itemsWithoutComments.push(item);
      }
    });
  }

  const options = [
    {
      id: optionsIDs.ALL,
      value: t('COLLECTION_GALLERY_TAB_FILTER_ALL', {
        count: collection.itemsCount,
      }),
      isSelectable: true,
      items: collection.items,
    },
    { divider: true },
    {
      id: optionsIDs.COMMENTS,
      value: t('COLLECTION_GALLERY_TAB_FILTER_COMMENTS', {
        count: itemsWithComments.length,
      }),
      isSelectable: true,
      items: itemsWithComments,
    },
    {
      id: optionsIDs.NO_COMMENTS,
      value: t('COLLECTION_GALLERY_TAB_FILTER_NO_COMMENTS', {
        count: itemsWithoutComments.length,
      }),
      isSelectable: true,
      items: itemsWithoutComments,
    },
  ];
  return options;
};

export const CollectionItemsFilter = ({
  collectionsWixCodeApi,
  isMobile,
  options,
  currentOption,
  setCurrentOption,
}: CollectionItemsFilterProps) => {
  const defaultOption = options[0];

  return (
    <FloatingDropdown
      options={options}
      label=""
      placeholder={defaultOption.value}
      className={st(classes.root, { mobile: isMobile })}
      value={currentOption.id}
      onChange={(option: any) => {
        setCurrentOption(option);
        collectionsWixCodeApi.setItems(option.items);
      }}
    ></FloatingDropdown>
  );
};
