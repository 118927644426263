import React from 'react';
import { BasicModal, IBasicModal } from '../BasicModal/BasicModal';
import { useTranslation } from '@wix/yoshi-flow-editor';

type SignUpModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  onSave?: () => void;
  onError?: () => void;
  origin?: string;
  onCancel?: () => void;
};

export const SignUpModal = ({
  isOpen,
  onClose = () => {},
  onSave = () => {},
  origin,
  onCancel,
}: SignUpModalProps) => {
  const { t } = useTranslation();

  const modalProps = {
    isOpen,
    primaryButtonText: t(`SIGN_UP_MODAL_CONFIRM_CTA`),
    onPrimaryButtonClick: onSave,
    onClose,
    secondaryButtonText: t(`SIGN_UP_MODAL_CANCEL_CTA`),
    onSecondaryButtonClick: onCancel || onClose,
    title: t(`SIGN_UP_MODAL${origin === 'comment' ? '_COMMENT' : ''}_TITLE`),
    content: t(
      `SIGN_UP_MODAL${origin === 'comment' ? '_COMMENT' : ''}_CONTENT`,
    ),
  } as IBasicModal;

  return <BasicModal {...modalProps} />;
};

export default SignUpModal;
