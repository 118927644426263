import React, { useEffect, useState } from 'react';
import { Popover, ActionsMenuLayout } from 'wix-ui-tpa';
import { st, classes } from './ThreeDotsMenu.st.css';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import More from '../../../assets/icons/vertical-dots.svg';

import _ from 'lodash';

type Placement = 'left-start' | 'left';

type ThreeDotsMenuProps = {
  menuItems: MenuItem[];
  placement?: Placement;
};

export enum MenuItemType {
  ITEM,
  DIVIDER,
}

export type MenuItem =
  | {
      type?: MenuItemType.ITEM;
      contentKey: string;
      onClick: () => void;
      prefixIcon?: JSX.Element;
      hidden?: boolean;
    }
  | { type: MenuItemType.DIVIDER; hidden?: boolean };

export const ThreeDotsMenu = ({
  menuItems,
  placement = 'left',
}: ThreeDotsMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const onClose = () => setIsOpen(false);
  const isHeader = placement === 'left-start';

  return (
    <>
      <Popover
        appendTo="parent"
        shown={isOpen}
        placement={placement}
        onClickOutside={onClose}
        fixed={true}
        showArrow={false}
        dynamicWidth={true}
        className={st(classes.popover, { mobile: isMobile, header: isHeader })}
      >
        <Popover.Element>
          <div
            className={st(classes.dotsWrapper, { isNewHeader: true })}
            onClick={() => setIsOpen(true)}
            data-hook="dots-wrapper-menu-btn"
          >
            <More className={classes.icon} />
          </div>
        </Popover.Element>
        <Popover.Content>
          <ActionsMenuLayout className={st(classes.menu, { isMobile })}>
            {menuItems
              .filter((item) => !item.hidden)
              .map((item) =>
                item.type === MenuItemType.DIVIDER ? (
                  <ActionsMenuLayout.Divider />
                ) : (
                  <ActionsMenuLayout.Item
                    content={t(item.contentKey)}
                    onClick={item.onClick}
                    prefixIcon={item.prefixIcon}
                  />
                ),
              )}
          </ActionsMenuLayout>
        </Popover.Content>
      </Popover>
    </>
  );
};
