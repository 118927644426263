import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Text,
  TextTypography,
  Button,
  ButtonPriority,
} from 'wix-ui-tpa';
import { st, classes } from './BasicModal.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface IBasicModal {
  isOpen: boolean;
  onSecondaryButtonClick?: () => void;
  onPrimaryButtonClick: () => void;
  primaryButtonText: string;
  secondaryButtonText?: string;
  title: string;
  content: string;
  onClose: () => void;
}

export const BasicModal = ({
  isOpen,
  onSecondaryButtonClick,
  onPrimaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  title,
  content,
  onClose,
}: IBasicModal) => {
  const { isMobile } = useEnvironment();
  const [animateShow, setAnimateShow] = useState(false);

  useEffect(() => {
    setAnimateShow(true);
  }, []);

  return (
    <Dialog
      className={classes.root}
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={st(classes.modalWrapper, { animateShow })}
      childrenWrapperClassName={st(classes.contentWrapper, { isMobile })}
    >
      <div className={st(classes.container, { mobile: isMobile })}>
        <Text className={classes.title} typography={TextTypography.largeTitle}>
          {title}
        </Text>
        <Text
          className={classes.content}
          typography={TextTypography.listText}
          tagName="div"
        >
          {content}
        </Text>
        <div className={classes.actions}>
          {secondaryButtonText && (
            <Button
              data-hook="dialog-secondary-btn"
              className={classes.secondaryButton}
              priority={ButtonPriority.basicSecondary}
              onClick={onSecondaryButtonClick}
            >
              {secondaryButtonText}
            </Button>
          )}
          <Button
            data-hook="dialog-primary-btn"
            className={classes.primaryButton}
            onClick={onPrimaryButtonClick}
          >
            {primaryButtonText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
