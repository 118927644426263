import React, { useEffect } from 'react';
import { CALLBACKS } from '../../consts/callbacks';

export const useCustomCallback = ({
  actionCallbacks,
  functions,
}: {
  actionCallbacks: Callbacks;
  functions: { [cbName: string]: Function };
}) => {
  useEffect(() => {
    if (actionCallbacks) {
      actionCallbacks.forEach((callback, i) => {
        if (callback && callback.callbackName in CALLBACKS) {
          functions[callback.callbackName](callback.args);
        }
      });
    }
  }, [actionCallbacks]);
};
