import React from 'react';
import { Member } from './Member';
import { useEnvironment } from '@wix/yoshi-flow-editor';

type MembersListProps = {
  membersStatus: MemberStatus[];
  navigateToMember: (memberId: string, memberSlug: string) => void;
  isAdmin: boolean;
};

export const MembersList = React.memo(
  ({ membersStatus, navigateToMember, isAdmin }: MembersListProps) => {
    const { isMobile } = useEnvironment();

    return membersStatus ? (
      <div>
        {membersStatus.map((memberStatus: MemberStatus) => {
          return (
            <Member
              member={memberStatus.member}
              status={memberStatus.memberStatus}
              navigateToMember={navigateToMember}
              isMobile={isMobile}
              isAdmin={isAdmin}
            />
          );
        })}
      </div>
    ) : (
      <div />
    );
  },
);
