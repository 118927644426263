import React from 'react';
import { st, classes } from './CollectionDetails.st.css';
import { Text , Avatar } from 'wix-ui-tpa';
import {
  ModalType,
  privacySettingsData,
  Status,
} from '../../../../../../consts/collectionsConsts';
import Time from 'wix-ui-icons-common/Time';
import { returnParsedDate } from '../../../../../../services/dateParser';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';


export const CollectionDetails = ({
  collection,
}: {
  collection: MediaCollection;
}) => {
  const privacyData = privacySettingsData[collection.privacySettings];

  const creator = collection.creator;
  const { t } = useTranslation();
  const { isMobile, language } = useEnvironment();

  return (
    <div className={st(classes.details, { mobile: isMobile })}>
      <div className={st(classes.privacy, { mobile: isMobile })}>
        <div className={classes.privacyIcon}>
          <privacyData.Icon className={classes.icon} />
        </div>
        <Text className={st(classes.privacyTitle, { mobile: isMobile })}>
          {t(privacyData.title)}
        </Text>
        <Text className={st(classes.privacyDesc, { mobile: isMobile })}>
          {t(privacyData.description)}
        </Text>
      </div>
      <div className={st(classes.dateCreated, { mobile: isMobile })}>
        <div className={classes.timeIcon}>
          <Time className={classes.icon} />
        </div>
        <Text className={st(classes.dateCreatedText, { mobile: isMobile })}>
          {returnParsedDate(collection.createdDate, language)}
        </Text>
      </div>
      <div className={classes.creator}>
        <Avatar
          className={classes.creatorPhoto}
          // name={member.nickname}
          src={creator.photoUrl}
        />
        <div className={classes.creatorDetails}>
          <Text className={st(classes.creatorTitle, { mobile: isMobile })}>
            {t('COLLECTION_ABOUT_TAB_CREATED_BY')}
          </Text>
          <Text className={st(classes.creatorName, { mobile: isMobile })}>
            {creator.nickname}
          </Text>
        </div>
      </div>
    </div>
  );
};
