import { GalleryLayout } from './galleryConsts';

export enum COLLLECTION_GALLERY_LOCATIONS {
  COLLETION,
  IMAGES_SELECTION,
}

export const listsDefaultStyleParams = {
  layoutParams: {
    cropRatio: 1.3333333333333333,
    navigationArrows: {
      type: 'DEFAULT_ARROW',
      container: {
        type: 'SHADOW',
        backgroundColor: 'rgba(18,18,18,0.6)',
        borderRadius: 0,
      },
      enable: true,
      padding: 23,
      verticalAlignment: 'ITEM_CENTER',
      size: 23,
      position: 'ON_GALLERY',
    },
    repeatingGroupTypes: '',
    structure: {
      galleryLayout: 2,
      itemSpacing: 40,
      numberOfGridRows: 1,
      scrollDirection: 'VERTICAL',
      layoutOrientation: 'VERTICAL',
      numberOfColumns: 3,
      responsiveMode: 'FIT_TO_SCREEN',
      scatter: {
        randomScatter: 0,
        manualScatter: '',
      },
      enableStreching: true,
      groupsOrder: 'BY_HEIGHT',
      columnRatios: [],
    },
    crop: {
      enable: true,
      enableSmartCrop: false,
      cropOnlyFill: false,
      ratios: [1.3333333333333333],
      method: 'FILL',
      alignment: 'CENTER',
    },
    targetItemSize: {
      minimum: 50,
      unit: 'SMART',
      value: 80,
    },
    groups: {
      density: 0,
      groupByOrientation: true,
      groupSize: 1,
      allowedGroupTypes: ['1'],
      numberOfGroupsPerRow: 0,
      repeatingGroupTypes: [],
    },
    thumbnails: {
      enable: false,
      spacing: 0,
      size: 120,
      alignment: 'BOTTOM',
    },
    info: {
      layout: 'NO_BACKGROUND',
      spacing: 10,
      border: {
        width: 0,
        radius: 0,
      },
      sizeUnits: 'PERCENT',
      width: 50,
      height: 0,
      placement: 'OVERLAY',
    },
    gallerySpacing: 0,
  },
  groupTypes: '1',
  numberOfImagesPerRow: 3,
  isAutoSlideshow: false,
  autoSlideshowType: 'interval',
  itemShadowOpacityAndColor: {
    value: 'rgba(18,18,18,0.2)',
    themeName: 'color_15',
  },
  arrowsColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,1)',
  },
  textBoxBorderColor: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  stylingParams: {
    itemResolutionMode: 'SCALED_DOWN',
    itemShadowBlur: 20,
    itemShadowDirection: 135,
    itemShadowSize: 10,
    itemEnableShadow: false,
    itemBorderRadius: 0,
    itemBorderWidth: 0,
  },
  gotStyleParams: true,
  selectedLayout: 0,
  allowSocial: false,
  allowDownload: false,
  allowTitle: true,
  allowDescription: true,
  loveButton: false,
  loveCounter: true,
  gallerySliderImageRatio: 1.7777777777777777,
  galleryImageRatio: 2,
  collageAmount: 0,
  floatingImages: 0,
  viewMode: 'preview',
  galleryHorizontalAlign: 'center',
  galleryVerticalAlign: 'center',
  mobilePanorama: false,
  imageLoadingWithColorMode: 'PICKED_COLOR',
  imageRatioType: 'FIXED',
  numberOfDisplayedItems: 3,
  expandAnimation: 'NO_EFFECT',
  itemBorderColor: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  titleDescriptionSpace: 6,
  textsVerticalPadding: 0,
  textsHorizontalPadding: 50,
  textBoxFillColor: {
    themeName: 'color_12',
    value: 'rgba(0,0,0,1)',
  },
  alwaysShowHover: false,
  previewHover: false,
  calculateTextBoxHeightMode: 'MANUAL',
  jsonStyleParams: '',
  designedPresetId: -1,
  galleryLayoutType: '',
  allowOverlayGradient: false,
  overlayGradientDegrees: 180,
  slideDuration: 400,
  overlayType: 0,
  customArrowsSvg: '',
  customArrowsRotation: 0,
  shouldUseCustomArrows: false,
  isArrowPrivateMedia: false,
  useCustomButton: false,
  customButtonText: 'Buy Now',
  isStoreGallery: true,
  isInAdi: false,
  galleryImageRatioFromWix: 1.3333333333333333,
  itemOpacity: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,0.4)',
  },
  itemFont: {
    family: 'worksans-semibold,work sans,sans-serif',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
    size: 24,
    preset: 'Heading-L',
    editorKey: 'font_4',
    fontStyleParam: true,
    displayName: 'Heading 4',
    value:
      'font:normal normal bold 24px/1.4em worksans-semibold,work sans,sans-serif;',
  },
  itemFontSlideshow: {
    family: 'worksans-extralight,work sans,sans-serif',
    displayName: 'Heading 5',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
    size: 22,
    preset: 'Custom',
    editorKey: 'font_5',
    fontStyleParam: true,
    value:
      'font:normal normal bold 22px/27px worksans-extralight,work sans,sans-serif;',
  },
  itemDescriptionFontSlideshow: {
    family: 'worksans-extralight,work sans,sans-serif',
    displayName: 'Paragraph 2',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
    size: 15,
    preset: 'Custom',
    editorKey: 'font_8',
    fontStyleParam: true,
    value:
      'font:normal normal bold 15px/18px worksans-extralight,work sans,sans-serif;',
  },
  itemDescriptionFont: {
    family: 'worksans-extralight,work sans,sans-serif',
    displayName: 'Paragraph 2',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
    size: 15,
    preset: 'Custom',
    editorKey: 'font_8',
    fontStyleParam: true,
    value:
      'font:normal normal bold 15px/18px worksans-extralight,work sans,sans-serif;',
  },
  itemFontColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,1)',
  },
  itemFontColorSlideshow: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  itemDescriptionFontColor: {
    themeName: 'color_11',
    value: 'rgba(123,111,222,1)',
  },
  itemDescriptionFontColorSlideshow: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  loadMoreButtonFont: {
    family: 'worksans-extralight,work sans,sans-serif',
    displayName: 'Paragraph 2',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
    size: 16,
    preset: 'Body-M',
    editorKey: 'font_8',
    fontStyleParam: true,
    value:
      'font:normal normal bold 16px/1.4em worksans-extralight,work sans,sans-serif;',
  },
  loadMoreButtonFontColor: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  loadMoreButtonColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,1)',
  },
  loadMoreButtonBorderColor: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  customButtonFontForHover: {
    family: 'worksans-extralight,work sans,sans-serif',
    displayName: 'Paragraph 2',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
    size: 15,
    preset: 'Custom',
    editorKey: 'font_8',
    fontStyleParam: true,
    value:
      'font:normal normal bold 15px/18px worksans-extralight,work sans,sans-serif;',
  },
  customButtonFontColorForHover: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  externalCustomButtonColor: {
    themeName: 'color_18',
    value: 'rgba(72,170,240,0)',
  },
  externalCustomButtonBorderColor: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  customButtonFont: {
    family: 'worksans-extralight,work sans,sans-serif',
    displayName: 'Paragraph 2',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
    size: 15,
    preset: 'Custom',
    editorKey: 'font_8',
    fontStyleParam: true,
    value:
      'font:normal normal bold 15px/18px worksans-extralight,work sans,sans-serif;',
  },
  customButtonFontColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,1)',
  },
  customButtonColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,0)',
  },
  customButtonBorderColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,1)',
  },
  sharpParams: {
    quality: 90,
    usm: {},
  },
  oneColorAnimationColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,1)',
  },
  responsive: true,
  galleryType: 'Columns',
  targetItemSize: 830,
  cubeRatio: 1.3333333333333333,
  externalInfoHeight: 0,
  externalInfoWidth: 0,
  isRTL: false,
  isVertical: true,
  minItemSize: 50,
  groupSize: 1,
  chooseBestGroup: true,
  cubeImages: true,
  cubeType: 'fill',
  smartCrop: false,
  fullscreen: true,
  videoLoop: true,
  videoSound: false,
  videoPlay: 'hover',
  videoSpeed: 1,
  collageDensity: 0,
  galleryTextAlign: 'center',
  imageMargin: 40,
  showArrows: true,
  hasThumbnails: false,
  galleryThumbnailsAlignment: 'bottom',
  gridStyle: 0,
  titlePlacement: 'SHOW_ON_HOVER',
  hoveringBehaviour: 'NO_CHANGE',
  slideshowLoop: false,
  playButtonForAutoSlideShow: false,
  pauseAutoSlideshowOnHover: true,
  allowSlideshowCounter: false,
  autoSlideshowInterval: 4,
  arrowsSize: 23,
  slideshowInfoSize: 200,
  imageLoadingMode: 'COLOR',
  scrollAnimation: 'FADE_IN',
  overlayAnimation: 'FADE_IN',
  imageHoverAnimation: 'NO_EFFECT',
  itemBorderWidth: 0,
  itemBorderRadius: 0,
  itemEnableShadow: false,
  itemShadowBlur: 20,
  loadMoreAmount: 'all',
  itemShadowDirection: 135,
  itemShadowSize: 10,
  imageInfoType: 'NO_BACKGROUND',
  textBoxBorderRadius: 0,
  textBoxBorderWidth: 0,
  textBoxHeight: 0,
  textImageSpace: 10,
  scrollDirection: 0,
  slideAnimation: 'SCROLL',
  autoSlideshowContinuousSpeed: 50,
  galleryLayout: 2,
  gallerySizeType: 'smart',
  allowContextMenu: false,
  showVideoPlayButton: true,
  gallerySize: 80,
  cropOnlyFill: false,
  numberOfImagesPerCol: 1,
  groupsPerStrip: 0,
  scatter: 0,
  rotatingScatter: '',
  enableInfiniteScroll: true,
  thumbnailSpacings: 0,
  enableScroll: true,
  scrollSnap: false,
  itemClick: 'nothing',
  scrollDuration: 400,
  arrowsPosition: 0,
  arrowsVerticalPosition: 'ITEM_CENTER',
  arrowsPadding: 23,
  thumbnailSize: 120,
  magicLayoutSeed: 1,
  imagePlacementAnimation: 'NO_EFFECT',
  calculateTextBoxWidthMode: 'PERCENT',
  textBoxWidth: 200,
  textBoxWidthPercent: 50,
  loadMoreButtonText: '',
  showVideoControls: false,
  shouldIndexDirectShareLinkInSEO: false,
  slideTransition: 'cubic-bezier(0.46,0.1,0.25,1)',
  useMaxDimensions: false,
  enableVideoPlaceholder: true,
  overlayPosition: 'BOTTOM',
  overlaySize: 100,
  overlaySizeType: 'PERCENT',
  overlayPadding: 0,
  cubeFitPosition: 'MIDDLE',
  magnificationLevel: 2,
  rotatingGroupTypes: '',
  fixedColumns: 0,
  behaviourParams: {
    item: {
      content: {
        magnificationValue: 2,
        hoverAnimation: 'NO_EFFECT',
        loader: 'COLOR',
        placementAnimation: 'NO_EFFECT',
      },
      video: {
        loop: true,
        enablePlayButton: true,
        playTrigger: 'HOVER',
        volume: 0,
        speed: 1,
        enableControls: false,
        enablePlaceholder: true,
      },
      overlay: {
        hoverAnimation: 'FADE_IN',
        position: 'BOTTOM',
        size: 100,
        sizeUnits: 'PERCENT',
        padding: 0,
        hoveringBehaviour: 'DISAPPEARS',
      },
      clickAction: 'NOTHING',
    },
    gallery: {
      scrollAnimation: 'FADE_IN',
      enableIndexingShareLinks: false,
      horizontal: {
        slideAnimation: 'SCROLL',
        slideTransition: 'cubic-bezier(0.46,0.1,0.25,1)',
        loop: false,
        autoSlide: {
          interval: 4,
          pauseOnHover: true,
          speed: 50,
          behaviour: 'OFF',
        },
        slideshowInfo: {
          enableCounter: false,
          enablePlayButton: false,
          buttonsAlignment: 'CENTER',
        },
        blockScroll: false,
        enableScrollSnap: false,
        navigationDuration: 400,
      },
      vertical: {
        loadMore: {
          enable: false,
          amount: 'ALL',
          text: '',
        },
      },
      blockContextMenu: true,
      layoutDirection: 'LEFT_TO_RIGHT',
    },
  },
  rotatingCropRatios: '',
  gallerySizePx: 0,
  gallerySizeRatio: 0,
  columnWidths: '',
  placeGroupsLtr: false,
};

export const collectionDefaultStyleParams = {
  allowContextMenu: false,
  allowDescription: false,
  allowDownload: false,
  allowSlideshowCounter: false,
  allowSocial: false,
  allowTitle: true,
  alwaysShowHover: false,
  arrowsSize: 23,
  autoSlideshowInterval: 4,
  calculateTextBoxHeightMode: 'MANUAL',
  chooseBestGroup: true,
  collageAmount: 0.8,
  collageDensity: 0.8,
  cubeImages: false,
  cubeRatio: 1,
  cubeType: 'fill',
  customButtonText: 'Buy Now',
  designedPresetId: -1,
  enableInfiniteScroll: true,
  expandAnimation: 'NO_EFFECT',
  fixedColumns: 0,
  floatingImages: 0,
  fullscreen: true,
  fullscreenLove: false,
  galleryHorizontalAlign: 'center',
  galleryImageRatio: 2,
  galleryLayout: 1,
  galleryLayoutType: '',
  galleryMargin: 0,
  gallerySize: 30,
  gallerySliderImageRatio: 1.7777777777777777,
  galleryTextAlign: 'center',
  galleryThumbnailsAlignment: 'bottom',
  galleryVerticalAlign: 'center',
  gotStyleParams: true,
  gridStyle: 1,
  groupSize: 3,
  groupTypes: '1,2h,2v,3t,3b,3l,3r',
  hasThumbnails: false,
  imagePlacementAnimation: 'SLIDE',
  imageHoverAnimation: 'ZOOM_IN',
  imageInfoType: 'NO_BACKGROUND',
  imageLoadingMode: 'BLUR',
  imageLoadingWithColorMode: 'PICKED_COLOR',
  imageMargin: 10,
  imageRatioType: 'FIXED',
  isAutoSlideshow: false,
  isRTL: false,
  isSlideshow: false,
  isStoreGallery: true,
  isVertical: false,
  itemBorderColor: 'color-5',
  itemIconColor: { value: 'var(--mcSecondaryColor)' },
  itemBorderRadius: 0,
  itemBorderWidth: 0,
  itemClick: 'expand',
  itemEnableShadow: false,
  itemShadowBlur: 20,
  itemShadowDirection: 135,
  itemShadowOpacityAndColor: { value: 'rgba(0, 0, 0, 0.2)' },
  itemShadowSize: 10,
  jsonStyleParams: '',
  loadMoreAmount: 'all',
  loveButton: false,
  loveCounter: false,
  m_designedPresetId: -1,
  m_jsonStyleParams: '',
  minItemSize: 120,
  mobilePanorama: false,
  numberOfDisplayedItems: 3,
  numberOfImagesPerRow: 4,
  // oneRow: false,
  overlayAnimation: 'NO_EFFECT',
  playButtonForAutoSlideShow: false,
  previewHover: false,
  responsive: false,
  scrollAnimation: 'NO_EFFECT',
  scrollDirection: 0,
  selectedLayout: 0,
  showArrows: true,
  showVideoPlayButton: true,
  slideAnimation: 'SCROLL',
  slideshowInfoSize: 200,
  slideshowLoop: false,
  smartCrop: false,
  textBoxBorderColor: 'color-5',
  textBoxBorderRadius: 0,
  textBoxBorderWidth: 0,
  textBoxFillColor: 'color-2',
  textBoxHeight: 0,
  textImageSpace: 10,
  textsHorizontalPadding: 0,
  textsVerticalPadding: 0,
  thumbnailSize: 120,
  thumbnailSpacings: 0,
  titleDescriptionSpace: 6,
  titlePlacement: 'SHOW_ON_HOVER',
  useCustomButton: true,
  videoLoop: true,
  videoPlay: 'hover',
  videoSound: false,
  videoSpeed: 1,
  viewMode: 'preview',
  overlayBackground: 'rgba(0,0,0,0)',
  commentsButton: true,
  m_numberOfImagesPerRow: 3,
  m_imageMargin: 0,
  hoveringBehaviour: 'NO_CHANGE',
  layoutParams: {
    gallerySpacing: 0,
  },
};

const setsDefualtStyleParams = {
  isRTL: false,
  isVertical: true,
  minItemSize: 50,
  groupSize: 1,
  chooseBestGroup: true,
  groupTypes: '1',
  cubeImages: true,
  cubeType: 'fill',
  smartCrop: false,
  fullscreen: true,
  videoLoop: true,
  videoSound: false,
  videoPlay: 'hover',
  videoSpeed: 1,
  numberOfImagesPerRow: 3,
  collageDensity: 0,
  galleryTextAlign: 'center',
  imageMargin: 40,
  fixedColumns: 0,
  showArrows: true,
  hasThumbnails: false,
  galleryThumbnailsAlignment: 'bottom',
  gridStyle: 0,
  titlePlacement: 'SHOW_ON_HOVER',
  hoveringBehaviour: 'DISAPPEARS',
  isAutoSlideshow: false,
  slideshowLoop: false,
  playButtonForAutoSlideShow: false,
  pauseAutoSlideshowOnHover: true,
  allowSlideshowCounter: false,
  autoSlideshowInterval: 4,
  arrowsSize: 23,
  slideshowInfoSize: 200,
  imageLoadingMode: 'COLOR',
  scrollAnimation: 'NO_EFFECT',
  overlayAnimation: 'FADE_IN',
  imageHoverAnimation: 'NO_EFFECT',
  itemBorderWidth: 0,
  itemBorderRadius: 0,
  itemEnableShadow: false,
  itemShadowBlur: 20,
  loadMoreAmount: 'all',
  itemShadowDirection: 135,
  itemShadowSize: 10,
  imageInfoType: 'NO_BACKGROUND',
  textBoxBorderRadius: 0,
  textBoxBorderWidth: 0,
  textBoxHeight: 0,
  textImageSpace: 10,
  scrollDirection: 0,
  slideAnimation: 'SCROLL',
  autoSlideshowType: 'interval',
  autoSlideshowContinuousSpeed: 50,
  behaviourParams: {
    item: {
      content: {
        magnificationValue: 2,
        hoverAnimation: 'NO_EFFECT',
        loader: 'COLOR',
        placementAnimation: 'NO_EFFECT',
      },
      overlay: {
        hoverAnimation: 'FADE_IN',
        position: 'BOTTOM',
        size: 100,
        sizeUnits: 'PERCENT',
        padding: 0,
        hoveringBehaviour: 'DISAPPEARS',
      },
      clickAction: 'NOTHING',
    },
    gallery: {
      scrollAnimation: 'NO_EFFECT',
      enableIndexingShareLinks: false,
      horizontal: {
        slideAnimation: 'SCROLL',
        slideTransition: 'cubic-bezier(0.46,0.1,0.25,1)',
        loop: false,
        autoSlide: {
          interval: 4,
          pauseOnHover: true,
          speed: 50,
          behaviour: 'OFF',
        },
        slideshowInfo: {
          enableCounter: false,
          enablePlayButton: false,
          buttonsAlignment: 'CENTER',
        },
        blockScroll: false,
        enableScrollSnap: false,
        navigationDuration: 400,
      },
      vertical: {
        loadMore: {
          enable: false,
          amount: 'ALL',
          text: '',
        },
      },
      blockContextMenu: true,
      layoutDirection: 'LEFT_TO_RIGHT',
    },
  },
  galleryLayout: 2,
  gallerySizePx: 0,
  gallerySizeRatio: 0,
  gallerySizeType: 'smart',
  itemShadowOpacityAndColor: {
    value: 'rgba(18,18,18,0.2)',
    themeName: 'color_15',
  },
  textBoxBorderColor: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  allowContextMenu: false,
  hidePlay: false,
  gallerySize: 30,
  cropOnlyFill: false,
  rotatingCropRatios: '',
  columnWidths: '',
  numberOfImagesPerCol: 1,
  groupsPerStrip: 0,
  scatter: 0,
  rotatingScatter: '',
  placeGroupsLtr: false,
  enableInfiniteScroll: true,
  thumbnailSpacings: 0,
  enableScroll: true,
  scrollSnap: false,
  itemClick: 'link',
  scrollDuration: 400,
  arrowsPosition: 0,
  arrowsVerticalPosition: 'ITEM_CENTER',
  arrowsPadding: 23,
  thumbnailSize: 120,
  magicLayoutSeed: 1,
  imagePlacementAnimation: 'NO_EFFECT',
  calculateTextBoxWidthMode: 'PERCENT',
  textBoxWidth: 200,
  textBoxWidthPercent: 50,
  loadMoreButtonText: '',
  showVideoControls: false,
  shouldIndexDirectShareLinkInSEO: false,
  slideTransition: 'cubic-bezier(0.46,0.1,0.25,1)',
  useMaxDimensions: false,
  enableVideoPlaceholder: true,
  overlayPosition: 'BOTTOM',
  overlaySize: 100,
  overlaySizeType: 'PERCENT',
  overlayPadding: 0,
  cubeFitPosition: 'MIDDLE',
  magnificationLevel: 2,
  stylingParams: {
    itemResolutionMode: 'SCALED_DOWN',
    itemShadowBlur: 20,
    itemShadowDirection: 135,
    itemShadowSize: 10,
    itemEnableShadow: false,
    itemBorderRadius: 0,
    itemBorderWidth: 0,
  },
  gotStyleParams: true,
  selectedLayout: 0,
  allowSocial: false,
  allowDownload: false,
  allowTitle: true,
  allowDescription: false,
  loveButton: false,
  loveCounter: true,
  showVideoPlayButton: true,
  gallerySliderImageRatio: 1.3333333333333333,
  galleryImageRatio: 2,
  collageAmount: 0,
  floatingImages: 0,
  viewMode: 'preview',
  galleryHorizontalAlign: 'center',
  galleryVerticalAlign: 'center',
  mobilePanorama: false,
  imageLoadingWithColorMode: 'PICKED_COLOR',
  imageRatioType: 'FIXED',
  numberOfDisplayedItems: 3,
  expandAnimation: 'NO_EFFECT',
  itemBorderColor: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  titleDescriptionSpace: 6,
  textsVerticalPadding: 0,
  textsHorizontalPadding: 0,
  textBoxFillColor: {
    themeName: 'color_12',
    value: 'rgba(0,0,0,1)',
  },
  alwaysShowHover: false,
  previewHover: false,
  calculateTextBoxHeightMode: 'MANUAL',
  jsonStyleParams: '',
  designedPresetId: -1,
  galleryLayoutType: '',
  allowOverlayGradient: false,
  overlayGradientDegrees: 180,
  slideDuration: 400,
  overlayType: 0,
  customArrowsSvg: '',
  customArrowsRotation: 0,
  shouldUseCustomArrows: false,
  isArrowPrivateMedia: false,
  useCustomButton: false,
  customButtonText: 'Click here',
  isStoreGallery: false,
  isInAdi: false,
  galleryImageRatioFromWix: 1.3333333333333333,
  itemOpacity: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,0.5)',
  },
  itemFont: {
    family: 'worksans-semibold,work sans,sans-serif',
    style: {
      bold: true,
      italic: false,
      underline: false,
    },
    size: 18,
    preset: 'Body-L',
    editorKey: 'font_7',
    fontStyleParam: true,
    displayName: 'Paragraph 1',
    value:
      'font:normal normal bold 18px/1.4em worksans-semibold,work sans,sans-serif;',
  },
  itemFontColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,1)',
  },
  itemFontColorSlideshow: {
    themeName: 'color_15',
    value: 'rgba(18,18,18,1)',
  },
  sharpParams: {
    quality: 90,
    usm: {},
  },
  oneColorAnimationColor: {
    themeName: 'color_11',
    value: 'rgba(255,255,255,1)',
  },
  responsive: true,
  galleryType: 'Columns',
  targetItemSize: 405,
  cubeRatio: 1.3333333333333333,
  externalInfoHeight: 0,
  externalInfoWidth: 0,
  rotatingGroupTypes: '',
};

export const getDefaultStyleParamsByLayout = ({
  layout,
  isMobile,
}: {
  layout: GalleryLayout;
  isMobile?: boolean;
}) => {
  let additionalStyleParams = {};
  switch (layout) {
    case GalleryLayout.MASONRY:
      additionalStyleParams = { galleryLayout: 1 };
      break;
    case GalleryLayout.GRID:
    default:
      additionalStyleParams = {
        galleryLayout: 2,
        gridStyle: 1,
        numberOfImagesPerRow: isMobile ? 3 : 4,
        imageMargin: isMobile ? 5 : 10,
      };
  }

  return { ...collectionDefaultStyleParams, ...additionalStyleParams };
};

export const getSetsDefaultStyleParams = () => {
  return listsDefaultStyleParams;
};

export const getEditorDefaultGalleryStyles = () => {
  return {
    ...collectionDefaultStyleParams,
    galleryLayout: 4,
    oneRow: true,
    useCustomButton: false,
  };
};

export const getDefaultSilderStyleParams = () => {
  return {
  selectedGalleryLayout: 'slider',
  galleryLayout: 4,
  showArrows: true,
  cubeImages: true,
  smartCrop: false,
  isVertical: false,
  gridStyle: 1,
  galleryType: 'Strips',
  groupSize: 1,
  groupTypes: '1',
  oneRow: true,
  hasThumbnails: false,
  enableScroll: true,
  isGrid: false,
  isSlider: true,
  isColumns: false,
  isMasonry: false,
  isSlideshow: false,
  cropOnlyFill: true,
  rotatingGroupTypes: '',
  minItemSize: 120,
  cubeRatio: 1,
  rotatingCropRatios: '',
}
}
