import React, { useCallback } from 'react';
import { BasicModal, IBasicModal } from '../BasicModal/BasicModal';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { translateWithParams } from '../../../utils';

type JoinCollectionModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  onSave?: () => void;
  onError?: () => void;
  listName?: string;
  title?: string;
  content?: string;
  ctaText?: string;
};

export const JoinCollectionModal = ({
  isOpen,
  onClose = () => {},
  onSave = () => {},
  listName,
  title,
  content,
  ctaText,
}: JoinCollectionModalProps) => {
  const { t } = useTranslation();

  const modalProps = {
    isOpen,
    primaryButtonText: ctaText || t(`JOIN_COLLECTION_MODAL_CONFIRM_CTA`),
    onPrimaryButtonClick: onSave,
    onClose,
    title:
      title ||
      translateWithParams(t, `JOIN_COLLECTION_MODAL_TITLE`, { listName }),
    content: content || t(`JOIN_COLLECTION_MODAL_CONTENT`),
  } as IBasicModal;

  return <BasicModal {...modalProps} />;
};
