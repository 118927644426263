import React, { useEffect, useState, useCallback } from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { st, classes } from './Collection.st.css';
import '../../../styles/CommentsTheming.global.scss';
import '../../../styles/CollectionsTheming.css';
import { CollectionWidget, CollectionWidgetProps } from './Collection';
import { TPAComponentsProvider } from 'wix-ui-tpa';
import { ToastProvider } from '../../../common/context/ToastContext/ToastContext';
import { RootPropsProvider } from '../../../common/context/RootPropsContext/RootPropsContext';
import { getModalGenerator } from './Modals/modals';
import { ModalsProvider } from '../../../common/context/ModalsContext/ModalsContext';
import { BiProvider } from '../../../common/context/BiContext/BiContext';
import { useWorkerPromise } from '../../../common/hooks/useWorkerPromise';

type WidgetProps = CollectionWidgetProps & { className?: string };

export default (props: WidgetProps) => {
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const {
    collection,
    user,
    instance,
    userStatus,
    instanceId,
    navigator,
    albumData,
    actions: _actions,
    promiseResult,
    onRefuseJoinRequest,
    onRefuseLoginRequest,
  } = props;

  if (!collection) {
    return <div></div>;
  }

  const actions = useWorkerPromise({ actions: _actions, promiseResult });

  const modalGenerator = getModalGenerator(
    collection,
    actions,
    navigator,
    albumData.settings.albumName,
    onRefuseJoinRequest,
    onRefuseLoginRequest,
  );

  useEffect(() => {
    props.refetchMembers();
  }, []);

  const biContextProps = {
    albumId: props.albumData?.settings?.id,
    instance_id: instanceId,
    visitorId: user?.id,
    role: user.role,
    collectionId: collection?.id,
  };

  return (
    <div
      data-hook="media-collections-wrapper"
      className={st(classes.root, { mobile: isMobile, responsive: props.host.isResponsive })}
    >
      <ToastProvider controllerToast={props.controllerToast}>
        <BiProvider {...biContextProps}>
          <RootPropsProvider
            value={{
              ...props,
              userStatus,
            }}
          >
            <TPAComponentsProvider value={{ mobile: isMobile, rtl: false }}>
              <ModalsProvider modalGenerator={modalGenerator}>
                <CollectionWidget
                  {...props}
                  collection={collection}
                  actions={actions}
                  userStatus={userStatus}
                />
              </ModalsProvider>
            </TPAComponentsProvider>
          </RootPropsProvider>
        </BiProvider>
      </ToastProvider>
    </div>
  );
};
