import React from 'react';
import { st, classes } from './AboutTab.st.css';
import {
  ModalType,
  privacySettingsData,
  Status,
} from '../../../../../../consts/collectionsConsts';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { TextButton, TextButtonPriority, IconButton } from 'wix-ui-tpa';

import EditIcon from '../../../../../../assets/icons/Edit.svg';
import { useModal } from '../../../../../../common/context/ModalsContext/ModalsContext';
import { CollectionDetails } from '../common/CollectionDetails';
import { CollectionNameAndDescription } from '../common/CollectionNameAndDescription';
import { useToast } from '../../../../../../common/context/ToastContext/ToastContext';
import {
  isNotListMember,
  isListAdmin,
} from '../../../../../../common/helpers/permissions';

type AboutTabProps = {
  collection: MediaCollection;
  onEditInfo: () => void;
  userStatus: Status;
};

export const AboutTab = ({ collection, userStatus }: AboutTabProps) => {
  const privacyData = privacySettingsData[collection.privacySettings];

  const creator = collection.creator;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { showModal } = useModal();
  const { triggerToast } = useToast();
  const notMember = isNotListMember(userStatus);
  const isAdmin = isListAdmin(userStatus);

  const onEditInfo = () => {
    let modalInfo = {};
    if (notMember) {
      modalInfo = {
        type: ModalType.JOIN,
      };
    } else {
      modalInfo = {
        type: ModalType.EDIT_INFO,
      };
    }
    showModal(modalInfo);
  };

  let additionalButton;
  if (isMobile && isAdmin) {
    additionalButton = (
      <IconButton
        className={st(classes.editButton, { mobile: isMobile })}
        onClick={onEditInfo}
        icon={<EditIcon />}
      />
    );
  }
  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      {!isMobile && isAdmin && (
        <div className={st(classes.editInfo)}>
          <TextButton
            priority={TextButtonPriority.secondary}
            onClick={onEditInfo}
          >
            {t('COLLECTION_ABOUT_TAB_EDIT_INFO')}
          </TextButton>
        </div>
      )}
      <CollectionNameAndDescription
        collection={collection}
        additionalButton={additionalButton}
      />
      <CollectionDetails collection={collection} />
    </div>
  );
};
